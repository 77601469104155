.navigation-video {
	display: flex;
}

.navigation-video .loading-indicator-container {
	position: absolute;
	align-self: center;
	justify-self: center;
	left: 50%;
	transform: translate(-50%, 0);
}

.navigation-video .loading-indicator {
	border: 3px solid transparent;
	border-top: 3px solid white;
	border-bottom: 3px solid white;
	border-right: 3px solid white;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 2s linear infinite;
}

.navigation-video video {
	position: relative;
	object-fit: contain;
	border-radius: inherit;
}

.navigation-video .joystick-container {
	position: absolute;
}

.greyVideo {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
